var client;
var google;
let scriptEle = document.createElement("script");
let mapKey = '';

let route = `https://maps.googleapis.com/maps/api/js?key=${getKey()}&libraries=places&language=en&callback=getKey`;
scriptEle.setAttribute("src", route);

let stripeRouteEle = document.createElement("script");
route = 'https://js.stripe.com/v3/';
stripeRouteEle.setAttribute("src", route);

function getKey() {
    if (window.location.href.includes('.financemutual.com')) {
        mapKey = 'AIzaSyAa0GBtCtHP69nQ3EhYc69G2xyXXXAQCYE';
    } else {
        mapKey = 'AIzaSyAa0GBtCtHP69nQ3EhYc69G2xyXXXAQCYE'
    }
    return mapKey;
}
// document.head.appendChild(scriptEle);
document.head.appendChild(stripeRouteEle);